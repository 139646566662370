const AmazonCognitoIdentity = require('amazon-cognito-identity-js')
const adminPoolIds = require(`./sync-backend-admin-auth/user-pool-ids.js`)

const adminPoolData = {
    UserPoolId: adminPoolIds.poolId,
    ClientId: adminPoolIds.poolClientId
}

const adminUserPool = new AmazonCognitoIdentity.CognitoUserPool(adminPoolData)

function authDetails(username, password) {
    const authenticationData = {
        Username: username,
        Password: password
    }

    return new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)
}

function signIn(username, password, signinCb) {
    const authenticationDetails = authDetails(username, password)

    const Pool = adminUserPool
    const userData = {
        Username: username,
        Pool
    }

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    return cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            signinCb('success', result, null)
        },
        onFailure: function (error) {
            signinCb('failure', null, error.message)
        }
    })
}

function getSession(resolveCb) {
    const cognitoUser = adminUserPool.getCurrentUser()

    if (cognitoUser != null) {
        //return value of cognitoUser.getSession() - awaited in requireAuth lifecycle method:
        cognitoUser.getSession(function (error, session) {
            if (error) {
                console.error(error.message || JSON.stringify(error))
                resolveCb(false)
            } else {
                if (session.isValid()) {
                    resolveCb(true) //localStorage and Redux are synced and valid - access granted
                } else {
                    //There is a user session in localStorage, but it is invalid:
                    alert('Session is not valid')
                    resolveCb(false)
                }
            }
        })
    } else {
        //No user session in localStorage - return false:
        resolveCb(false)
    }
}

function getIdToken(resolveCb) {
    const cognitoUser = adminUserPool.getCurrentUser()

    if (cognitoUser != null) {
        //leaving non-strict equality because this is how AWS example was.

        return cognitoUser.getSession(function (error, session) {
            if (error) {
                console.error(error.message || JSON.stringify(error))
                resolveCb(null)
            } else {
                if (session.isValid()) {
                    resolveCb(session.idToken.jwtToken)
                } else {
                    console.error('getIdToken(): Session is invalid')
                    resolveCb(null)
                }
            }
        })
    } else {
        resolveCb(null)
    }
}

function signOut(cb = () => null) {
    const cognitoUser = adminUserPool.getCurrentUser()

    if (cognitoUser != null) {
        //Remove session from localStorage:
        cognitoUser.signOut()
    }
    cb()
}

module.exports = {
    signIn,
    getSession,
    getIdToken,
    signOut
}
