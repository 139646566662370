const iconColors = {
    'Sales Pipeline - Rooftop Lease': '#2ab351',
    'Potential Prospects': '#ff7912',
    'On-Site Prospects': '#388eff'
}

const getIconColors = (pipeline) => {
    return iconColors[pipeline] || '#999999'
}

export default getIconColors
