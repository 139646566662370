import {Button, Menu} from 'antd'
import styles from './Sidebar.module.css'
import LayerSelectorGroup from '../Common/LayerSelectorGroup/LayerSelectorGroup'

import Icon, {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons'
import {LayersSvg} from '../../assets'
import {useEffect, useState} from 'react'
import {Divider, Layout} from '../../../node_modules/antd/lib/index'
import {logoSymbolImage} from '../../assets/img/index'
import SignOut from '../Common/SignOut/SignOut'
import Loading from '../Common/Loading/Loading'

const {Sider} = Layout

function SidebarMenuContent({activeKey, onOptionsChange, dataSource}) {
    switch (activeKey) {
        case 'layers':
            return <LayerSelectorGroup dataSource={dataSource} onChange={onOptionsChange} />
        default:
            return ''
    }
}

function Sidebar({
    defaultSelectedMenu,
    initialLayerSelectorsData,
    onLayerSelectorsChange,
    loading,
    loadingPipeline
}) {
    const [selectedMenuKey, setSelectedMenuKey] = useState(defaultSelectedMenu)
    const [collapsed, setCollapsed] = useState(false)
    const [layerSelectorsData, setLayerSelectorsData] = useState({})

    useEffect(() => {
        if (collapsed) {
            setSelectedMenuKey('')
        } else if (!selectedMenuKey) {
            setSelectedMenuKey(defaultSelectedMenu)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed, defaultSelectedMenu])

    useEffect(() => {
        setLayerSelectorsData(initialLayerSelectorsData)
    }, [initialLayerSelectorsData])

    const handleMenuClick = (event) => {
        setSelectedMenuKey(event.key)
        setCollapsed(false)
    }

    const handleOptionsChange = (change) => {
        const parentIndex = layerSelectorsData.findIndex(({key}) => change.key[0] === key)
        const parent = layerSelectorsData[parentIndex]
        if (parent) {
            let nextLayerSelectors = [...parent.layerSelectors]
            if (change.key.length === 1) {
                // If parent is modified then change all children checked value
                nextLayerSelectors =
                    parent.layerSelectors &&
                    parent.layerSelectors.length &&
                    parent.layerSelectors.map((layerSelector) => ({
                        ...layerSelector,
                        checked: change.value
                    }))
            } else if (change.key.length === 2) {
                // If children is modified then change children checked value
                const layerSelectorIndex = parent.layerSelectors.findIndex(
                    ({key}) => change.key[1] === key
                )
                nextLayerSelectors[layerSelectorIndex] = {
                    ...parent.layerSelectors[layerSelectorIndex],
                    checked: change.value
                }
            }
            const nextGroup = {
                ...parent,
                layerSelectors: nextLayerSelectors,
                checked: nextLayerSelectors.some((subItem) => subItem.checked)
            }
            const nextLayerSelectorsData = [...layerSelectorsData]
            nextLayerSelectorsData[parentIndex] = nextGroup
            setLayerSelectorsData(nextLayerSelectorsData)
            onLayerSelectorsChange(nextLayerSelectorsData)
        }
    }

    return (
        <Sider
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            width={270}
            className={styles.container}
        >
            <div className={styles.header}>
                <div className={!collapsed ? styles.logoContainer : styles.logoContainerCollapsed}>
                    <div className={styles.logo}>
                        <img src={logoSymbolImage} alt="SaveSolar Logo" />
                        <span>{!collapsed && 'SolarSight'}</span>
                    </div>
                    <Button
                        className={styles.collapseButton}
                        type="primary"
                        shape="circle"
                        onClick={() => setCollapsed(!collapsed)}
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    />
                </div>
                {collapsed && <Divider />}
            </div>
            <div className={styles.content}>
                <Menu
                    className={styles.menu}
                    selectedKeys={[selectedMenuKey]}
                    mode={collapsed ? 'inline' : 'horizontal'}
                    inlineCollapsed={collapsed}
                    onClick={handleMenuClick}
                >
                    <Menu.Item
                        className={collapsed ? styles.menuItemCollapsed : ''}
                        key="layers"
                        icon={<Icon component={LayersSvg} />}
                    >
                        Layers
                    </Menu.Item>
                </Menu>
                {loading ? (
                    <Loading />
                ) : (
                    layerSelectorsData &&
                    layerSelectorsData.length && (
                        <SidebarMenuContent
                            activeKey={selectedMenuKey}
                            onOptionsChange={handleOptionsChange}
                            dataSource={layerSelectorsData}
                        />
                    )
                )}
                {loadingPipeline && !loading ? <Loading /> : null}
            </div>
            {!collapsed && (
                <div className={styles.footer}>
                    <SignOut />
                </div>
            )}
        </Sider>
    )
}

export default Sidebar
