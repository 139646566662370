import {useState} from 'react'
import Animate from 'rc-animate'
import styles from './SquareSelector.module.css'
import './Square.animate.css'
import SquareSelectorOption from './SquareSelectorOption'

function SquareSelector({defaultValue, onChange, options}) {
    const [showOptions, setShowOptions] = useState(false)
    const [selectedOption, setSelectedOption] = useState(defaultValue)

    function handleSelectedOptionClick() {
        setShowOptions(!showOptions)
    }

    function handleOptionClick(newSelectedOption) {
        setSelectedOption(newSelectedOption)
        setShowOptions(false)
        onChange(newSelectedOption)
    }

    return (
        <div className={styles.container}>
            <div className={styles.selectedOption} onClick={handleSelectedOptionClick}>
                <SquareSelectorOption option={selectedOption} selected />
            </div>
            <Animate transitionName="open">
                {showOptions && (
                    <div className={styles.optionsContainer}>
                        {options &&
                            options.length &&
                            options.map((option) => (
                                <SquareSelectorOption
                                    key={option.id}
                                    onClick={() => handleOptionClick(option)}
                                    option={option}
                                    selected={selectedOption && selectedOption.id === option.id}
                                />
                            ))}
                    </div>
                )}
            </Animate>
        </div>
    )
}

export default SquareSelector
