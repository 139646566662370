import {useEffect, useState} from 'react'
import {xor} from 'lodash'
import {Collapse} from 'antd'
import {CaretRightOutlined} from '@ant-design/icons'
import LayerSelector from './LayerSelector'
import styles from './LayerSelectorGroup.module.css'
import LayerSelectorParent from './LayerSelectorParent'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {MAP_LOCATION} from '../../../redux/actionTypes'

const {Panel} = Collapse

function LayerSelectorGroup({onChange, dataSource}) {
    const dispatch = useDispatch()

    /* state hooks */
    const [activeKeys, setActiveKeys] = useState()

    /* redux hooks */
    const locationValue = useSelector((state) => state.location.coordinates) || {}

    /* effect hooks */
    useEffect(() => {
        const nextDefaultActiveKeys =
            dataSource && dataSource.length
                ? dataSource.filter(({checked}) => checked).map(({key}) => key)
                : []
        setActiveKeys(nextDefaultActiveKeys)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* handlers */
    const handlePanelHeaderClick = (key) => {
        const newActiveKeys = xor(activeKeys, [key]) // Adds or removes an item depending on their existence
        setActiveKeys(newActiveKeys)
    }

    const handleFilterLayer = ({coordinates = {}, zone}, value) => {
        const {longitude, latitude} = coordinates
        if (value && longitude && latitude) {
            dispatch({
                type: MAP_LOCATION,
                payload: {longitude, latitude, zone}
            })
        }
    }

    const dataSourceFiltered =
        dataSource &&
        dataSource.filter((src) =>
            src.zone || src.hide ? src.zone === locationValue.zone && !src.hide : src
        )

    return (
        <Collapse
            bordered={false}
            activeKey={activeKeys}
            onChange={() => {}}
            expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className={styles.collapse}
        >
            {dataSourceFiltered &&
                dataSourceFiltered.length &&
                dataSourceFiltered.map((group) => (
                    <Panel
                        className={styles.panel}
                        header={
                            <LayerSelectorParent
                                title={group.title}
                                defaultChecked={group.defaultChecked}
                                checked={group.checked}
                                onClick={() => handlePanelHeaderClick(group.key)}
                                onChange={(value) => {
                                    onChange({key: [group.key], value})
                                    handleFilterLayer(group, value)
                                }}
                            />
                        }
                        key={group.key}
                    >
                        {(group.layerSelectors &&
                            group.layerSelectors.length &&
                            group.layerSelectors.map((layerSelector) => (
                                <LayerSelector
                                    key={layerSelector.key}
                                    title={layerSelector.title}
                                    defaultChecked={layerSelector.defaultChecked}
                                    checked={layerSelector.checked}
                                    label={layerSelector.label}
                                    filters={layerSelector.filters}
                                    layerKey={layerSelector.key}
                                    onChange={(value) => {
                                        onChange({key: [group.key, layerSelector.key], value})
                                    }}
                                />
                            ))) ||
                            ''}
                    </Panel>
                ))}
        </Collapse>
    )
}

export default LayerSelectorGroup
