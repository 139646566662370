import styles from './LayerSelectorParent.module.css'

import {Switch} from 'antd'
function LayerSelectorParent({onClick, title, ...props}) {
    return (
        <div>
            <div className={styles.container}>
                <div onClick={onClick} className={styles.title}>
                    <b>{title}</b>
                </div>
                <Switch {...props} />
            </div>
        </div>
    )
}

export default LayerSelectorParent
