import React, {useState, useEffect, useRef} from 'react'
import {Layout} from 'antd'
import _ from 'lodash'

import {getIdToken} from '../auth/auth'

import requireAdminAuth from '../Common/requireAdminAuth'
import Loading from '../Common/Loading/Loading'
import Sidebar from '../Sidebar/Sidebar'
import layerSelectorsInitialData from './layerSelectors.json'
import getIconColors from './getIconColors'
import Map from './Map'
import styles from './MapPage.module.css'
import {getQueryFromFilters} from './MapPageUtils'
import {useSelector} from 'react-redux'

const {Content} = Layout

function requestKey(token, url, route, cb, setWaitingForResponse) {
    fetch(url + route, {
        method: 'GET',
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    })
        .then((response) => {
            if (response.status !== 200) {
                setWaitingForResponse(false)
                return null
            } else {
                setWaitingForResponse(false)
                return response.json()
            }
        })
        .then((data) => {
            cb(data)
        })
        .catch((error) => {
            setWaitingForResponse(false)
            throw error
        })
}

function MapPage({apiEndpoint}) {
    /* redux hooks */
    // const locationValue = useSelector((state) => state.location.coordinates) || {}

    const [activeLayers, setActiveLayers] = useState([])
    const [layerFilterChange, setFilterChange] = useState(null)

    const [activePipelines, setActivePipelines] = useState([])
    const [pipelineQueryStringParameters, setPipelineQueryStringParameters] = useState('')
    const [layerSelectorsData, setLayerSelectorsData] = useState(layerSelectorsInitialData)
    const layerSelectorsDataRef = useRef(layerSelectorsData)

    const [deals, setDeals] = useState(null)
    const [nearmapResponse, setNearmapResponse] = useState({})
    const [hereResponse, setHereResponse] = useState({})

    const [loading, setLoading] = useState(true)
    const [waitingForNearmapResponse, setWaitingForNearmapResponse] = useState(false)
    const [waitingForHereResponse, setWaitingForHereResponse] = useState(false)
    const [waitingForPipelinesResponse, setWaitingForPipelinesResponse] = useState(false)
    const [mapPageLoaded, setMapPageLoaded] = useState(false)

    const {nearmapApiKey} = nearmapResponse
    const {hereApiKey} = hereResponse

    const filtersNextApply = useSelector((state) => state.filters.nextApply)

    const handleLayerSelectorsChange = (nextValueOrig) => {
        const handleDealsMenuSection = (nextValue) => {
            let hubspotMenuIndex
            let hubspotMenu
            try {
                hubspotMenuIndex = nextValue.findIndex((value) => value.key === 'hubspot')
                hubspotMenu = nextValue[hubspotMenuIndex]
            } catch (_) {}
            const checkedPipelines = !!(hubspotMenu && hubspotMenu.checked)
            if (!checkedPipelines) {
                return {
                    queryStringParams: '',
                    nextValue,
                    checkedPipelines
                }
            }
            //IIFE:
            const queryStringParams = ((hubspotMenu) => {
                try {
                    const selectedPipelineHsIds = hubspotMenu.layerSelectors
                        .filter((selector) => selector.checked === true)
                        .map((selector) => selector.key)
                    if (selectedPipelineHsIds.length) {
                        let result = '?'
                        for (const selected of selectedPipelineHsIds) {
                            result += `${selected}=showpipeline&`
                        }
                        result = result.slice(0, result.length - 1)
                        return result
                    } else {
                        return ''
                    }
                } catch (error) {
                    console.log(error)
                    console.error(
                        'Error: setPipelineQueryStringParameters() could not extract selectedPipelineHsIds'
                    )
                    return ''
                }
            })(hubspotMenu)
            nextValue.splice(hubspotMenuIndex, 1)
            return {
                queryStringParams,
                nextValue,
                checkedPipelines
            }
        }

        const {queryStringParams, nextValue, checkedPipelines} = handleDealsMenuSection([
            ...nextValueOrig
        ])

        setPipelineQueryStringParameters(queryStringParams)

        const layerSelectors = _.flatMap(nextValue, 'layerSelectors')
        const newActiveLayers = layerSelectors
            .filter((layerSelector) => layerSelector && layerSelector.checked)
            .map(({key}) => key)
        if (checkedPipelines) {
            newActiveLayers.push('dealsMarkers')
        }
        setActiveLayers(newActiveLayers)
    }

    const handleLayerFilterChange = (nextChange) => {
        const {layerKey, filtersValue} = nextChange
        const filtersList = _.map(filtersValue, (value, propertyKey) => {
            return {
                propertyKey,
                layerKey,
                ...value
            }
        })
        const query = getQueryFromFilters(filtersList)
        const newFilterChange = {query, layerKey}
        setFilterChange(newFilterChange)
    }

    const updateLayerSelectorData = (layerSelectorsData, activePipelines) => {
        const layerSelectorsDataClone = [...layerSelectorsData]
        const indexOfHubspotSelector = layerSelectorsDataClone.findIndex(
            (layer) => layer.key === 'hubspot'
        )
        const updatedPipelines = []
        activePipelines.forEach((pipeline) => {
            const color = getIconColors(pipeline.pipeline_label)
            updatedPipelines.push({
                title: pipeline.pipeline_label,
                key: pipeline.pipeline_hs_id,
                checked: true,
                label: {
                    type: 'stroke',
                    data: [
                        {
                            legend: pipeline.pipeline_label,
                            thickness: 30,
                            color: color
                        }
                    ]
                }
            })
        })
        layerSelectorsDataClone[indexOfHubspotSelector].layerSelectors = updatedPipelines
        return layerSelectorsDataClone
    }

    useEffect(() => {
        handleLayerSelectorsChange(layerSelectorsData)
    }, [layerSelectorsData])

    useEffect(() => {
        const updated = updateLayerSelectorData(layerSelectorsDataRef.current, activePipelines)
        setLayerSelectorsData(updated)
    }, [activePipelines])

    useEffect(() => {
        const {layerKey, filtersValue} = filtersNextApply
        const filtersList = _.map(filtersValue, (value, propertyKey) => {
            return {
                propertyKey,
                layerKey,
                ...value
            }
        })
        const query = getQueryFromFilters(filtersList)
        const newFilterChange = {query, layerKey}
        setFilterChange(newFilterChange)
    }, [filtersNextApply])

    // const [colorScheme, setColorScheme] = useState('Heat');

    useEffect(() => {
        setWaitingForNearmapResponse(true)
        setWaitingForHereResponse(true)
        setWaitingForPipelinesResponse(true)
        getIdToken((token) => {
            requestKey(
                token,
                apiEndpoint,
                '/nearmap-key',
                setNearmapResponse,
                setWaitingForNearmapResponse
            )
            requestKey(token, apiEndpoint, '/here-key', setHereResponse, setWaitingForHereResponse)
            requestKey(
                token,
                apiEndpoint,
                '/pipelines',
                setActivePipelines,
                setWaitingForPipelinesResponse
            )
        })
    }, [apiEndpoint])

    useEffect(() => {
        if (pipelineQueryStringParameters) {
            setLoading(true)
            getIdToken((token) => {
                requestKey(
                    token,
                    apiEndpoint,
                    `/layers/hubspot${pipelineQueryStringParameters}`,
                    setDeals,
                    setLoading
                )
            })
        } else {
            setDeals({type: 'FeatureCollection', features: []})
        }
    }, [apiEndpoint, pipelineQueryStringParameters])

    useEffect(() => {
        if([loading, waitingForNearmapResponse, waitingForHereResponse, waitingForPipelinesResponse].every(e => e === false)){
            setMapPageLoaded(true)
        }
    }, [loading, waitingForNearmapResponse, waitingForHereResponse, waitingForPipelinesResponse])

    return (
        <Layout className={styles.container}>
            <Sidebar
                defaultSelectedMenu="layers"
                initialLayerSelectorsData={layerSelectorsData}
                onLayerSelectorsChange={handleLayerSelectorsChange}
                onLayerFilterChange={handleLayerFilterChange}
                loading={waitingForPipelinesResponse}
                loadingPipeline={loading}
            />
            <Content className={styles.content}>
                {nearmapApiKey && hereApiKey && deals ? (
                    <Map
                        apiKeys={{nearmapApiKey, hereApiKey}}
                        activeLayers={activeLayers}
                        deals={deals}
                        layerFilterChange={layerFilterChange}
                        mapPageLoaded={mapPageLoaded}
                    />
                ) : null}
                {loading || waitingForNearmapResponse || waitingForHereResponse ? (
                    <Loading />
                ) : null}
            </Content>
        </Layout>
    )
}

export default requireAdminAuth(MapPage)
