import React from 'react'
import styles from './SelectLayer.module.css'

import {Select} from 'antd'
import {useDispatch} from 'react-redux'
import {MAP_LOCATION} from '../../../redux/actionTypes'

const {Option} = Select

const layers = [
    {
        value: 'Washington, D.C.',
        name: 'Washington, D.C.'
    },
    {
        value: 'New Jersey',
        name: 'New Jersey'
    }
]

function SelectLayer(props) {
    const dispatch = useDispatch()

    const handleLayoutChange = (value) => {
        // Washington coordinates are selected by default
        let newCoordinates = {latitude: 38.907231, longitude: -77.036521, zone: 'WA'}

        //Be sure that every 'zone' match the US State zone name ('STUSPS' in 'solarsight-frontend/src/assets/kmls/cb_2018_us_state_20m.kml' file)
        if (value === 'New Jersey') {
            newCoordinates = {latitude: 39.626326, longitude: -74.804408, zone: 'NJ'}
        }

        const {longitude, latitude, zone} = newCoordinates

        dispatch({
            type: MAP_LOCATION,
            payload: {longitude, latitude, zone}
        })
    }

    return (
        <div className={styles.select_container}>
            <Select defaultValue={'Washington, D.C.'} onChange={handleLayoutChange}>
                {layers.map((layer, index) => (
                    <Option key={index} value={layer.value}>
                        {layer.name}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default SelectLayer
