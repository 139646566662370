function reverseGeocode(key, lat, long, cb) {
    fetch(
        `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat}%2C${long}&lang=en-US&apiKey=${key}`,
        {
            method: 'GET'
        }
    )
        .then((response) => {
            if (response.status !== 200) {
                console.error(`Response HTTP status: ${response.status}`)
                return null
            } else {
                return response.json()
            }
        })
        .then((data) => {
            if (data) {
                cb(data)
            }
        })
        .catch((error) => {
            throw error
        })
}

export default reverseGeocode
