import {divIcon, geoJSON, marker} from 'leaflet'
import {MarkerClusterGroup} from 'leaflet.markercluster'
import getIconColors from '../../components/Map/getIconColors'

const getDealsDivIcon = (pipeline) => {
    const color = getIconColors(pipeline)
    return divIcon({
        className: 'fontawesome-marker',
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        html: `<i class="fas fa-map-marker-alt" style="color:${color};"></i>`
    })
}

const selectedDealsDivIcon = divIcon({
    className: 'lmap-selected-marker',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    html: '<span></span>'
})

export function generateMarkers(geoJSONdata, onMarkerClick, existing) {
    let selectedMarkers = []

    function clusterHasSelectedMarker(cluster) {
        let hasSelectedMarker = false
        if (cluster._markers && cluster._markers.length) {
            hasSelectedMarker = cluster._markers.some((marker) => {
                const dealId = marker.feature.properties.deal_id
                return selectedMarkers.includes(dealId)
            })
        }
        if (!hasSelectedMarker && cluster._childClusters && cluster._childClusters.length) {
            return cluster._childClusters.some((childCluster) =>
                clusterHasSelectedMarker(childCluster)
            )
        }
        return hasSelectedMarker
    }

    function clusterIconCreate(cluster) {
        const hasSelectedMarker = clusterHasSelectedMarker(cluster)
        return divIcon({
            className: hasSelectedMarker ? 'lmap-selected-cluster-icon' : 'lmap-cluster-icon',
            html: '<b>' + cluster.getChildCount() + '</b>'
        })
    }

    function handleMarkerClick(e) {
        const layer = e.layer
        const markerId = layer.feature.properties.deal_id
        const isSelected = selectedMarkers.includes(markerId)

        isSelected ? removeSelectedMarker(markerId, layer) : setSelectedMaker(markerId, layer)
        onMarkerClick(layer.feature, !isSelected)

        function setSelectedMaker(markerId, layer) {
            layer.setIcon(selectedDealsDivIcon)
            selectedMarkers.push(markerId)
            markers.on('click', markersClickHandler)
            markers.refreshClusters(layer)
        }

        function removeSelectedMarker(markerId, layer) {
            const pipeline = layer.feature.properties.pipeline
            layer.setIcon(getDealsDivIcon(pipeline))
            selectedMarkers = selectedMarkers.filter((marker) => {
                return marker !== markerId
            })
            markers.off('click', markersClickHandler)
            markers.refreshClusters(layer)
        }

        function markersClickHandler(markerClickEvent) {
            const nextMarkerId = markerClickEvent.layer.feature.properties.deal_id
            if (markerId !== nextMarkerId) {
                removeSelectedMarker(markerId, layer)
            } else {
                markers.off('click', markersClickHandler)
            }
        }
    }

    const geoJSONLayer = geoJSON(geoJSONdata, {
        pointToLayer: (feature, latlng) => {
            const pipeline = feature && feature.properties && feature.properties.pipeline
            return marker(latlng, {icon: getDealsDivIcon(pipeline)})
        }
    })

    if (existing) {
        existing.clearLayers()
        existing.addLayer(geoJSONLayer)
        return existing
    }

    const markers = new MarkerClusterGroup({
        spiderfyOnMaxZoom: true,
        iconCreateFunction: clusterIconCreate,
        maxClusterRadius: 40
    })
    markers.addLayer(geoJSONLayer)
    markers.on('click', handleMarkerClick)

    return markers
}
