import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import 'leaflet/dist/leaflet.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'leaflet-geosearch/dist/geosearch.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import './index.less'
import '@fortawesome/fontawesome-free/js/all'

import Login from './components/Login/Login'
import MapPage from './components/Map/MapPage'

import {apiEndpoint} from './components/api/sync-api-endpoint/api-endpoint'

import {Provider} from 'react-redux'
import {store} from './redux/store.js'

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route path="/login" render={(props) => <Login {...props} />} />
                <Route
                    path="/"
                    render={(props) => <MapPage {...props} apiEndpoint={apiEndpoint} />}
                />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
)
