import Icon from '@ant-design/icons'
import {DollarSignSvg} from 'assets'
import styles from './LayerSelectorLabel.module.css'
import LayerSelectorToolTitle from '../LayerSelectorToolTitle/LayerSelectorToolTile'
import {v4 as uuidv4} from 'uuid'

const ICON_COMPONENT_BY_NAME = {
    'dollar-sign': DollarSignSvg
}

function IconLegend({icon, legend, color}) {
    return (
        <div className={styles.iconContainer}>
            <Icon
                className={styles.icon}
                style={{color}}
                component={ICON_COMPONENT_BY_NAME[icon]}
            />
            <span className={styles.legend}>{legend}</span>
        </div>
    )
}

function StrokeLegend({background, height, legend}) {
    return (
        <div className={styles.strokeContainer}>
            <div className={styles.stroke} style={{background, height}}></div>
            <span className={styles.legend}>{legend}</span>
        </div>
    )
}

function LayerSelectorLabel({type, dataSource}) {
    return (
        <div className={styles.container}>
            <LayerSelectorToolTitle>Legend</LayerSelectorToolTitle>
            {dataSource &&
                dataSource.length &&
                dataSource.map((label) => {
                    if (type === 'stroke') {
                        return (
                            <StrokeLegend
                                key={uuidv4()}
                                background={label.color}
                                height={label.thickness}
                                legend={label.legend}
                            />
                        )
                    } else if (type === 'icon') {
                        return (
                            <IconLegend
                                key={uuidv4()}
                                icon={label.icon}
                                legend={label.legend}
                                color={label.color}
                            />
                        )
                    }
                    return ''
                })}
        </div>
    )
}

export default LayerSelectorLabel
