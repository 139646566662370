import L from 'leaflet'
import reverseGeocode from '../reverseGeocode'

export function initReverseGeocodeListener(map, hereApiKey) {
    let revGeocodeMarker
    map.on('click', (event) => {
        const lat = event.latlng && event.latlng.lat
        const lng = event.latlng && event.latlng.lng
        const cb = (data) => {
            const resultLat =
                data.items && data.items[0] && data.items[0].position && data.items[0].position.lat
            const resultLng =
                data.items && data.items[0] && data.items[0].position && data.items[0].position.lng
            const resultAddress =
                data.items && data.items[0] && data.items[0].address && data.items[0].address.label
            if (!revGeocodeMarker) {
                revGeocodeMarker = L.marker([resultLat, resultLng]).addTo(map)
                revGeocodeMarker
                    .bindPopup(
                        `<b>Address:</b><br>${resultAddress || 'No address returned in result'}`
                    )
                    .openPopup()
                revGeocodeMarker.on('contextmenu', (e) => {
                    revGeocodeMarker.removeFrom(map)
                })
            } else {
                revGeocodeMarker.removeFrom(map)
                revGeocodeMarker = L.marker([resultLat, resultLng]).addTo(map)
                revGeocodeMarker
                    .bindPopup(
                        `<b>Address:</b><br>${resultAddress || 'No address returned in result'}`
                    )
                    .openPopup()
                revGeocodeMarker.on('contextmenu', (e) => {
                    revGeocodeMarker.removeFrom(map)
                })
            }
        }
        reverseGeocode(hereApiKey, lat, lng, cb)
    })
}
