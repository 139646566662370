import {Button} from 'antd'
import {useEffect, useState} from 'react'
import RangeFilter from './RangeFilter'
import styles from './LayerSelectorFilters.module.css'
import LayerSelectorToolTitle from '../LayerSelectorToolTitle/LayerSelectorToolTile'
import {useDispatch, useSelector} from 'react-redux'
import {APPLY_FILTER, SET_FILTER_VALUES} from '../../../redux/actionTypes'
import {map} from 'lodash'

const filterComponentMap = {
    range: RangeFilter
}

function LayerSelectorFilters({filters, layerKey}) {
    const dispatch = useDispatch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filtersValue = useSelector((state) => state.filters.values[layerKey]) || {}
    const [invalidFilters, setInvalidFilters] = useState(false)

    useEffect(() => {
        const invalidList = map(filtersValue, 'isInvalid')
        const newInvalidFilters = invalidList.some((value) => value === true)
        setInvalidFilters(newInvalidFilters)
    }, [filtersValue])

    const handleFilterChange = (propertyKey, filterValue) => {
        const newFiltersValue = {
            ...filtersValue,
            [propertyKey]: filterValue
        }
        dispatch({
            type: SET_FILTER_VALUES,
            payload: {
                [layerKey]: newFiltersValue
            }
        })
    }

    const handleApplyClick = () => {
        dispatch({
            type: APPLY_FILTER,
            payload: {
                layerKey,
                filtersValue
            }
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <LayerSelectorToolTitle>Filters</LayerSelectorToolTitle>
                <Button
                    className={styles.applyBtn}
                    onClick={handleApplyClick}
                    type="primary"
                    size="small"
                    disabled={invalidFilters}
                >
                    Apply all
                </Button>
            </div>
            <div className={styles.filtersContainer}>
                {filters &&
                    filters.length &&
                    filters.map((filter) => {
                        const FilterComponent = filterComponentMap[filter.type] || ''
                        return (
                            <FilterComponent
                                key={filter.property}
                                label={filter.label}
                                minValid={filter.minValid}
                                defaultMin={filter.defaultMin}
                                maxValid={filter.maxValid}
                                defaultMax={filter.defaultMax}
                                initialValue={filtersValue[filter.property]}
                                onChange={(filterValue) =>
                                    handleFilterChange(filter.property, filterValue)
                                }
                            />
                        )
                    })}
            </div>
        </div>
    )
}

export default LayerSelectorFilters
