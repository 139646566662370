import {APPLY_FILTER, SET_FILTER_VALUES, MAP_LOCATION} from './actionTypes'

const filtersInitialState = {
    values: {},
    nextApply: {}
}

export const filters = (state = filtersInitialState, action) => {
    switch (action.type) {
        case SET_FILTER_VALUES: {
            return {
                ...state,
                values: {
                    ...state.values,
                    ...action.payload
                }
            }
        }
        case APPLY_FILTER: {
            return {
                ...state,
                nextApply: action.payload
            }
        }
        default: {
            return state
        }
    }
}

const locationInitialState = {
    coordinates: {latitude: 38.907231, longitude: -77.036521, zone: 'WA'}
}

export const location = (state = locationInitialState, action) => {
    switch (action.type) {
        case MAP_LOCATION: {
            const {latitude, longitude, zone} = action.payload
            return {
                ...state,
                coordinates: {latitude, longitude, zone}
            }
        }
        default: {
            return state
        }
    }
}
