import {GeoSearchControl, HereProvider} from 'leaflet-geosearch'

export function initSearchControl(map, hereApiKey, icon) {
    //Set up leaflet-geosearch:
    const provider = new HereProvider({
        params: {
            apiKey: hereApiKey
        }
    })

    const searchControl = new GeoSearchControl({
        provider,
        style: 'bar',
        marker: {
            icon // https://stackoverflow.com/questions/49441600/react-leaflet-marker-files-not-found
        },
        retainZoomLevel: false,
        animateZoom: true,
        zoomLevel: 20,
        autoClose: true,
        keepResult: true
    })

    map.addControl(searchControl)
    return searchControl
}
