// overlay color functions
export function getColorAllowablePvKv(AllowablePvKv) {
    if (AllowablePvKv === undefined) return '#969696'
    if (AllowablePvKv === 99999) {
        return '#ff00c5'
    } else if (AllowablePvKv > 1500 && AllowablePvKv <= 15000) {
        return '#00bf40'
    } else if (AllowablePvKv > 1000 && AllowablePvKv <= 1500) {
        return '#b7ff00'
    } else if (AllowablePvKv > 500 && AllowablePvKv <= 1000) {
        return '#ffdd00'
    } else if (AllowablePvKv > 250 && AllowablePvKv <= 500) {
        return '#ff8c00'
    } else if (AllowablePvKv > 0 && AllowablePvKv <= 250) {
        return '#ff0000'
    } else if (AllowablePvKv === 0) {
        return '#000000'
    } else {
        return '#969696'
    }
}

export function getColorByMw(mw) {
    if (mw === undefined) return '#969696'
    if (mw > 5000) {
        return '#1a9641'
    } else if (mw > 2500 && mw <= 5000) {
        return '#a6d96a'
    } else if (mw > 1000 && mw <= 2500) {
        return '#ffffbf'
    } else if (mw > 100 && mw <= 1000) {
        return '#fdae61'
    } else if (mw <= 100) {
        return '#d7191c'
    } else {
        return '#969696'
    }
}

export function getClusterHeatColor(count) {
    if (count === undefined) return '#969696'
    if (count > 100) {
        return 'rgba(241, 128, 23, 0.6);'
    } else if (count > 10 && count <= 100) {
        return 'rgba(240, 194, 12, 0.6)'
    } else if (count <= 10) {
        return 'rgba(181, 226, 140, 0.6)'
    } else {
        return '#969696'
    }
}

export function getUtilityTerritoryBoundaryColor(type) {
    switch (type) {
        case 'JCP&L':
            return '#5bafc1'
        case 'PSE&G':
            return '#f9b868'
        case 'ACE':
            return '#eee477'
        case 'LDC':
            return '#e6deb3'
        default:
            return '#858584'
    }
}

// overlay templates
export function popupTemplate(title, template) {
    if (template) {
        return template
    } else {
        return `
        <table>
            <tr>
                <th>${title}<th>
            <tr>
                <td>OBJECTID</td>
                <td>{OBJECTID}</td>
            </tr>
            <tr>
                <td>FeederID</td>
                <td>{FeederID}</td>
            </tr>
            <tr>
                <td>Substation</td>
                <td>{Substation}</td>
            </tr>
            <tr>
                <td>Allowable_PV_kW</td>
                <td>{Allowable_PV_kW}</td>
            </tr>
            <tr>
                <td>Voltage</td>
                <td>{Voltage}</td>
            </tr>
            <tr>
                <td>Owner</td>
                <td>{Owner}</td>
            </tr>
            <tr>
                <td>Region</td>
                <td>{Region}</td>
            </tr>
            <tr>
                <td>State</td>
                <td>{State}</td>
            </tr>
            <tr>
                <td>Shape__Length</td>
                <td>{Shape__Length}</td>
            </tr>
        </table>
        `
    }
}

export const jcplhcPopupTemplate = `
<table>
    <tr>
        <th>Jersey Central Power & Light Hosting Capacity<th>
    <tr>
        <td>Remaining</td>
        <td>{Remaining}</td>
    </tr>
</table>
`

export const orhcPopupTemplate = `
<table>
    <tr>
        <th>Orange & Rockland Hosting Capacity<th>
    <tr>
        <td>OPERATING COMPANY</td>
        <td>{OPERATINGCOMPANY}</td>
    </tr>
    <tr>
        <td>CIRCUIT NAME</td>
        <td>{CIRCUIT_NAME}</td>
    </tr>
    <tr>
        <td>SUBSTATION NAME</td>
        <td>{SUBSTATION}</td>
    </tr>
    <tr>
        <td>VOLTAGE (KV)</td>
        <td>{VOLTAGE_KV}</td>
    </tr>
    <tr>
        <td>CONNECTED DER (MW)</td>
        <td>{CONNECTED_GEN}</td>
    </tr>
    <tr>
        <td>QUEUED DER (MW)</td>
        <td>{QUEUED_GEN}</td>
    </tr>
    <tr>
        <td>MINIMUM TOTAL FEEDER HOSTING CAPACITY (MW)</td>
        <td>{MIN_FDR_HC}</td>
    </tr>
    <tr>
        <td>MAXIMUM TOTAL FEEDER HOSTING CAPACITY (MW)</td>
        <td>{MAX_FDR_HC}</td>
    </tr>
    <tr>
        <td>HC_UPDATED_DATE</td>
        <td>{HC_UPDATED_DATE}</td>
    </tr>
    <tr>
        <td>DERS_UPDATED_DATE</td>
        <td>{DERS_UPDATED_DATE}</td>
    </tr>
</table>
`

export const pseghcPopupTemplate = `
<table>
    <tr>
        <th>Solar Power Suitability<th>
    <tr>
        <td>VOLTAGE</td>
        <td>{VOLTAGE}</td>
    </tr>
    <tr>
        <td>AVAILABLE kW</td>
        <td>{AVAILABILITYDISPLAY}</td>
    </tr>
</table>
`

export const utbnjPopupTemplate = `
<table>
    <tr>
        <th>Electric Utilities Territory Map: {NAME}<th>
    <tr>
        <td>NAME</td>
        <td>{NAME}</td>
    </tr>
    <tr>
        <td>DISTRICT</td>
        <td>{DISTRICT}</td>
    </tr>
    <tr>
        <td>LABEL</td>
        <td>{LABEL}</td>
    </tr>
    <tr>
        <td>TYPE</td>
        <td>{TYPE}</td>
    </tr>
    <tr>
        <td>PARENT</td>
        <td>{PARENT}</td>
    </tr>
    <tr>
        <td>OLD_NAME</td>
        <td>{OLD_NAME}</td>
    </tr>
</table>
`
