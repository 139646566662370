import {Spin} from 'antd'
import React from 'react'
import styles from './Loading.module.css'
import {LoadingOutlined} from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />

function Loading() {
    return (
        <div className={styles.container}>
            <Spin indicator={antIcon} tip="Loading..." size="large" />
        </div>
    )
}

export default Loading
