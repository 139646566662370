import React, {useState} from 'react'
import {Button, Form, FormGroup, Input, Spinner} from 'reactstrap'
import {logoImage} from '../../assets'
import {signIn} from '../auth/auth'

import {useLocation} from 'react-router-dom'

import './Login.css'

function updateState(event, stateCb) {
    stateCb(event.target.value)
}

function Login(props) {
    const queryParamString = useLocation().search
    const [emailValue, setEmailValue] = useState('')
    const [passwordValue, setPasswordValue] = useState('')
    const [loadingAuth, setLoadingAuth] = useState(false)

    return (
        <div id="login">
            <img id="savesolar-login-logo" src={`${logoImage}`} alt="SaveSolar Logo" />
            <Form>
                <FormGroup>
                    <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={emailValue}
                        onChange={(e) => updateState(e, setEmailValue)}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={passwordValue}
                        onChange={(e) => updateState(e, setPasswordValue)}
                    />
                </FormGroup>
                <div id="spinner-div">{loadingAuth ? <Spinner /> : null}</div>
                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        setLoadingAuth(true)
                        signIn(emailValue, passwordValue, (status, token, error) => {
                            if (status === 'success') {
                                setLoadingAuth(false)
                                props.history.push(`/${queryParamString ? queryParamString : ''}`)
                            } else {
                                setLoadingAuth(false)
                                alert(error)
                            }
                        })
                    }}
                >
                    Login
                </Button>
            </Form>
        </div>
    )
}

export default Login
