import {InputNumber, Typography} from 'antd'
import {useEffect, useState} from 'react'
import styles from './RangeFilter.module.css'

const {Text} = Typography

function RangeFilter({label, minValid, maxValid, initialValue = {}, onChange}) {
    const [value, setValue] = useState({})
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue.value || {})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {}, [value])

    const handleChange = (minMax, inputValue) => {
        const newValue = {
            ...value,
            [minMax]: inputValue
        }
        let isInvalid = newValue.min && newValue.max && newValue.min > newValue.max
        setValue(newValue)
        setErrorMsg(isInvalid ? 'Please enter a valid range' : '')
        onChange({
            type: 'range',
            value: newValue,
            isInvalid
        })
    }

    return (
        <div className={styles.container}>
            <label>{label}:</label>
            <InputNumber
                min={minValid}
                max={maxValid}
                value={value['min']}
                onChange={(value) => handleChange('min', value)}
                placeholder="min"
                className={styles.inputNumberLeft}
                step={10}
                size="small"
            />
            <InputNumber
                min={minValid}
                max={maxValid}
                value={value['max']}
                onChange={(value) => handleChange('max', value)}
                placeholder={maxValid || 'max'}
                className={styles.inputNumberRight}
                step={10}
                size="small"
            />
            <div className={styles.errorMsg}>
                {errorMsg && <Text type="danger">{errorMsg}</Text>}
            </div>
        </div>
    )
}

export default RangeFilter
