import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {Button} from 'antd'

import {gmapsLogo, nearmapLogo} from '../../assets/img'
import {usStatesBoundaries} from '../../assets/kmls'
import SolarSightMap from '../../libraries/solarSightMap'
import {BASEMAPS_KEYS} from '../../libraries/solarSightMap/baseMaps'
import SelectLayer from '../Common/SelectLayer/SelectLayer'
import SquareSelector from '../Common/SquareSelector/SquareSelector'
import omnivore from '@mapbox/leaflet-omnivore'
import L from 'leaflet'
import '../../libraries/solarSightMap/leaflet-measure-path.js'
import '../../libraries/solarSightMap/leaflet-measure-path.css'

import Info from '../Info/Info'
import styles from './Map.module.css'

const MAP_ID = 'map'
const solarSightMap = new SolarSightMap(MAP_ID)
const BASEMAP_OPTIONS = [
    {
        id: BASEMAPS_KEYS.nearmap,
        text: 'NM',
        image: nearmapLogo,
        description: 'Aerial Nearmap'
    },
    {
        id: BASEMAPS_KEYS.gmaps,
        text: 'GM',
        image: gmapsLogo,
        description: 'Google Maps'
    },
    {
        id: BASEMAPS_KEYS.auto,
        text: 'Auto',
        background: '#48b563',
        description: 'Automatic Switch'
    }
]
const DEFAULT_BASEMAP = BASEMAP_OPTIONS[2]

const infoDataAdapter = (properties) => {
    const keys = Object.keys(properties)
    return keys.map((key) => ({
        id: key,
        name: key.replaceAll('_', ' '),
        value: properties[key]
    }))
}

function Map({apiKeys, activeLayers, layerFilterChange, deals, mapPageLoaded}) {
    const location = useLocation()
    const address = new URLSearchParams(location.search).get('address')

    const [mapInitilized, setMapInitialized] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [infoData, setInfoData] = useState()
    const [activeBaseMap, setActiveBaseMap] = useState()
    const [zoom, setZoom] = useState(15)

    const locationValue = useSelector((state) => state.location.coordinates) || {}
    const usState = locationValue.zone

    const hanldeMarkerClick = useCallback((e, showInfoWindow) => {
        const {properties} = e
        if (showInfoWindow) {
            setShowInfo(true)
            setInfoData(infoDataAdapter(properties))
        } else {
            hanldeInfoClose()
        }
    }, [])

    const hanldeInfoClose = () => {
        setShowInfo(false)
        setInfoData(null)
    }

    const clearMeasurements = () => {
        solarSightMap.map.eachLayer(function (layer) {
            if (
                layer._title &&
                (layer._title === 'Segment length' || layer._title === 'Total area')
            ) {
                solarSightMap.map.removeLayer(layer)
                layer = null
            }
        })
        let element = document.querySelectorAll('.leaflet-pane.leaflet-overlay-pane g path')
        if (!!element) {
            document
                .querySelectorAll('.leaflet-pane.leaflet-overlay-pane g path')
                .forEach((e) => e.remove())
        }
    }
    //.querySelectorAll(
    //   '.leaflet-pane.leaflet-marker-pane .leaflet-zoom-animated.leaflet-measure-path-measurement'
    // )

    const defaultBorderStyle = useCallback((feature) => {
        if (feature.properties.styleUrl === '#KMLStyler0') {
            if (!!feature.properties.STUSPS) {
                return {
                    color: '#AAAAAA',
                    weight: 4,
                    fill: false
                }
            }
        }
    }, [])

    const highlightBorderStyle = useCallback(
        (feature) => {
            if (feature.properties.styleUrl === '#KMLStyler0') {
                if (!!feature.properties.STUSPS && feature.properties.STUSPS === usState) {
                    return {
                        color: '#1890FF',
                        weight: 10,
                        fill: false
                    }
                } else {
                    return {
                        fill: false,
                        opacity: 0
                    }
                }
            }
        },
        [usState]
    )

    const onEachFeature = useCallback(
        (feature, layer) => {
            if (feature.properties.styleUrl === '#KMLStyler0') {
                if (!!feature.properties.STUSPS && feature.properties.STUSPS === usState) {
                    solarSightMap.map.fitBounds(layer.getBounds(), true)
                }
            }
        },
        [usState]
    )

    const layerGeneration = useCallback(
        (styleFunction) => {
            let myCustomGroup = new L.geoJSON(null, {
                onEachFeature: onEachFeature,
                style: styleFunction,
                pane: 'statesPane'
            })
            return myCustomGroup
        },
        [onEachFeature]
    )

    useEffect(() => {
        if (mapInitilized) {
            let element = document.querySelectorAll('.leaflet-pane.leaflet-states-pane g path')
            if (!!element) {
                document
                    .querySelectorAll('.leaflet-pane.leaflet-states-pane g path')
                    .forEach((e) => e.remove())
            }
            let layerGroup = L.layerGroup().addTo(solarSightMap.map)
            let newDefaultBorderLayer = layerGeneration(defaultBorderStyle)
            let newhighlightedBorderLayer = layerGeneration(highlightBorderStyle)

            layerGroup.clearLayers()
            solarSightMap.map.createPane('statesPane')
            solarSightMap.map.getPane('statesPane').style.zIndex = 650
            omnivore.kml(usStatesBoundaries, null, newDefaultBorderLayer).addTo(layerGroup)
            omnivore.kml(usStatesBoundaries, null, newhighlightedBorderLayer).addTo(layerGroup)
        }
    }, [mapInitilized, usState, defaultBorderStyle, highlightBorderStyle, layerGeneration])

    useEffect(() => {
        solarSightMap.init(apiKeys)
        setMapInitialized(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (mapInitilized) {
            solarSightMap.getZoomMap(setZoom)
        }
    }, [mapInitilized])

    useEffect(() => {
        if (mapInitilized && address && mapPageLoaded) {

            const enterKeyPress = new KeyboardEvent('keydown', {
                key: 'Enter',
                code: 'Enter',
                which: 13,
                keyCode: 13,
            })

            const container = solarSightMap.searchControl.container
            const inputElement = container.querySelector('input')
            inputElement.value = address
            inputElement.dispatchEvent(enterKeyPress)
        }
    }, [mapInitilized, address, mapPageLoaded])

    useEffect(() => {
        if (deals && deals.features) {
            solarSightMap.updateDealsLayer(deals, hanldeMarkerClick)
        }
    }, [deals, hanldeMarkerClick])

    useEffect(() => {
        if (mapInitilized) {
            if (zoom <= 10) {
                solarSightMap.setActiveOverlayLayers([...activeLayers, 'utbnj'])
                return
            }

            if (zoom > 9) {
                solarSightMap.setActiveOverlayLayers(activeLayers.filter((e) => e !== 'utbnj'))
            } else {
                solarSightMap.setActiveOverlayLayers([])
            }
        }
    }, [activeLayers, mapInitilized, zoom])

    useEffect(() => {
        if (mapInitilized && layerFilterChange) {
            solarSightMap.filterLayer(layerFilterChange)
        }
    }, [layerFilterChange, mapInitilized])

    useEffect(() => {
        if (activeBaseMap) {
            activeBaseMap === BASEMAPS_KEYS.auto
                ? solarSightMap.setAutomaticBaseMapSwitching()
                : solarSightMap.setBaseMap(activeBaseMap)
        }
    }, [activeBaseMap])

    return (
        <Fragment>
            <div id={MAP_ID} className={styles.map}></div>
            <SelectLayer />
            <Button className={styles.clearButton} onClick={clearMeasurements}>
                Clear Map
            </Button>
            <SquareSelector
                onChange={(option) => {
                    setActiveBaseMap(option.id)
                }}
                defaultValue={DEFAULT_BASEMAP}
                options={BASEMAP_OPTIONS}
            />
            <Info show={showInfo} data={infoData} onClose={hanldeInfoClose} />
        </Fragment>
    )
}

export default React.memo(Map)
