import React, {Component} from 'react'

//Auth
import {getSession} from '../auth/auth'

const hoc = (WrappedComponent) => {
    class RequireAdminAuth extends Component {
        constructor(props) {
            super(props)
            this.state = {}
        }

        static async getDerivedStateFromProps(props) {
            const queryParamString = props.location && props.location.search

            const authorize = async (props) => {
                return new Promise((resolve) => {
                    //'Promise.resolve' is supplied as the callback function
                    getSession(resolve)
                })
            }

            const authorized = await authorize(props)

            if (!authorized) {
                props.history.push(`/login${queryParamString ? queryParamString : ''}`)
            }

            return null //avoids react errors
        }

        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    return RequireAdminAuth
}

export default hoc
