import styles from './Info.module.css'
import InfoSection from './InfoSection'
import {CloseCircleOutlined} from '@ant-design/icons'
import {Collapse} from 'antd'
import './Info.animate.css'
import Animate from 'rc-animate'

const {Panel} = Collapse

function Info({show, data, onClose}) {
    return (
        <Animate transitionName="slide">
            {show ? (
                <div className={styles.container}>
                    <div className={styles.topBar}>
                        Deal details
                        <CloseCircleOutlined className={styles.closeBtn} onClick={onClose} />
                    </div>
                    <Collapse ghost defaultActiveKey={['1']}>
                        <Panel
                            showArrow={false}
                            header={<div className={styles.sectionTitle}>Hubspot</div>}
                            key="1"
                        >
                            <InfoSection properties={data} />
                        </Panel>
                    </Collapse>
                </div>
            ) : null}
        </Animate>
    )
}

export default Info
