import L from 'leaflet'

export const BASEMAPS_KEYS = {
    nearmap: 'nearmap',
    gmaps: 'gmaps',
    auto: 'auto'
}

export function initBaseMaps(map, nearmapApiKey) {
    const url = `https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.img?apikey=${nearmapApiKey}`

    map.createPane('baseTiles')
    map.getPane('baseTiles').style.zIndex = 2

    map.createPane('backgroundFeature9weight')
    map.getPane('backgroundFeature9weight').style.zIndex = 3
    map.getPane('backgroundFeature9weight').style.pointerEvents = 'none'

    map.createPane('backgroundFeature4weight')
    map.getPane('backgroundFeature4weight').style.zIndex = 4
    map.getPane('backgroundFeature4weight').style.pointerEvents = 'none'

    map.createPane('foregroundFeature')
    map.getPane('foregroundFeature').style.zIndex = 5

    const nearmapTileBaseLayer = L.tileLayer(url, {
        pane: 'baseTiles',
        attribution: '&copy; Nearmap',
        maxNativeZoom: 22,
        maxZoom: 22,
        minZoom: 5,
        useCache: true
    })

    const gmapsTileBaseLayer = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        pane: 'baseTiles',
        maxZoom: 22,
        minZoom: 5,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    })

    const baseMaps = {
        [BASEMAPS_KEYS.nearmap]: nearmapTileBaseLayer,
        [BASEMAPS_KEYS.gmaps]: gmapsTileBaseLayer
    }

    return baseMaps
}
