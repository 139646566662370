import {LoadingOutlined} from '@ant-design/icons'
import {format} from 'date-fns'
import styles from './InfoSection.module.css'
import currency from 'currency.js'
import {v4 as uuidv4} from 'uuid'

const FULL_TIME_FORMAT = 'MM/dd/yyyy p'

const propertyComponentMap = {
    last_activity_date: DateProperty,
    deal_id: DealIdProperty,
    amount: AmountProperty,
    total_production: TotalProductionProperty
}

function DealIdProperty({id, value, name}) {
    return (
        <tr key={id}>
            <td className={styles.propertyName}>Hubspot Link</td>
            <td className={styles.propertyValue}>
                <a
                    href={`https://app.hubspot.com/contacts/4343906/deal/${value}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Deal {value}
                </a>
            </td>
        </tr>
    )
}

function DateProperty({id, value, name}) {
    return (
        <tr key={id}>
            <td className={styles.propertyName}>{name}</td>
            <td className={styles.propertyValue}>{format(new Date(value), FULL_TIME_FORMAT)}</td>
        </tr>
    )
}

function AmountProperty({id, value, name}) {
    return (
        <tr key={id}>
            <td className={styles.propertyName}>{name}</td>
            <td className={styles.propertyValue}>{currency(value, {precision: 0}).format()}</td>
        </tr>
    )
}

function TotalProductionProperty({id, value, name}) {
    return (
        <tr key={id}>
            <td className={styles.propertyName}>{name}</td>
            <td className={styles.propertyValue}>{value} kW</td>
        </tr>
    )
}

function DefaultProperty({id, value, name}) {
    return (
        <tr key={id}>
            <td className={styles.propertyName}>{name}</td>
            <td className={styles.propertyValue}>{value}</td>
        </tr>
    )
}

function InfoSection({properties}) {
    return (
        <div className={styles.container}>
            <table>
                <tbody>
                    {properties && properties.length ? (
                        properties.map((property) => {
                            const Property = propertyComponentMap[property.id] || DefaultProperty
                            return <Property {...property} key={uuidv4()} />
                        })
                    ) : (
                        <tr>
                            <td>
                                <LoadingOutlined />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default InfoSection
