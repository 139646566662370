import {Switch} from 'antd'
import {useState} from 'react'
import styles from './LayerSelector.module.css'
import LayerSelectorFilters from '../LayerSelectorFilters/LayerSelectorFilters'
import LayerSelectorLabel from './LayerSelectorLabel'

function LayerSelector({title, onClick, layerKey, label, filters, checked, ...props}) {
    const [expanded, setExpanded] = useState(false)

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div onClick={() => setExpanded(!expanded)} className={styles.title}>
                    {title}
                </div>
                <Switch checked={checked} {...props} size={'small'} />
            </div>
            {
                <div className={styles.content}>
                    {checked && filters && filters.length && (
                        <LayerSelectorFilters layerKey={layerKey} filters={filters} />
                    )}
                    {expanded && label && (
                        <LayerSelectorLabel type={label.type} dataSource={label.data} />
                    )}
                </div>
            }
        </div>
    )
}

export default LayerSelector
