import styles from './SquareSelectorOption.module.css'
import {Avatar} from 'antd'

function SquareSelectorOption({option, selected, onClick}) {
    const className = `${styles.avatar} ${selected ? styles.selected : ''}`
    return (
        <Avatar
            title={option.description}
            size={32}
            className={className}
            style={{backgroundColor: option.background, color: option.textColor}}
            src={option.image ? <img alt={option.text} src={option.image} /> : null}
            onClick={onClick}
        >
            {!option.image ? option.text : null}
        </Avatar>
    )
}

export default SquareSelectorOption
