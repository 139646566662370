const formatByFilterTypeMap = {
    range: ({value, propertyKey}) => {
        const queryList = []
        if (value.min) {
            queryList.push(`${propertyKey} >= ${value.min}`)
        }
        if (value.max) {
            queryList.push(`${propertyKey} <= ${value.max}`)
        }
        return queryList.join(' AND ').trim()
    }
}

export const getQueryFromFilters = (filtersList) => {
    return filtersList
        .map(({type, value, propertyKey}) => {
            const formatFunction = formatByFilterTypeMap[type]
            return formatFunction({value, propertyKey})
        })
        .join(' AND ')
        .trim()
}
