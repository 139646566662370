import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import {difference} from 'lodash'
import {BASEMAPS_KEYS, initBaseMaps} from './baseMaps'
import {generateMarkers} from './markers'
import {initOverlayLayers} from './overlayLayers'
import {initReverseGeocodeListener} from './reverseGeocodeListener'
import {initSearchControl} from './searchControl'
import '../tileCache'
import './styles.css'

import 'leaflet-measure'
import 'leaflet-measure/dist/leaflet-measure.css'
import './leaflet-measure-path.js'
import './leaflet-measure-path.css'
import './leaflet-measure.css'

function writeResults(results) {
    const coordinates = results.points
    let dynamicArray = []
    const mappedCoordinates = coordinates.map((points) => {
        const arrayCoord = [points.lat, points.lng]
        dynamicArray.push(arrayCoord)
        return arrayCoord
    })
    return mappedCoordinates
}

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
})

const AUTO_TILE_SWITCH_TRIGGER_ZOOM = 17
class SolarSightMap {
    constructor(mapId) {
        this.mapId = mapId
        this.activeOverlayLayers = []
    }

    init({nearmapApiKey, hereApiKey}) {
        this.map = L.map(this.mapId, {maxZoom: 22}).setView([38.907231, -77.036521], 16)
        L.Marker.prototype.options.icon = DefaultIcon
        const options = {
            position: 'topleft',
            primaryAreaUnit: 'sqfeet',
            secondaryAreaUnit: 'acres',
            activeColor: '#47a2ed',
            captureZIndex: 20000,
            popupOptions: {
                className: 'leaflet-measure-popup'
            }
        }
        new L.Control.Measure(options).addTo(this.map)
        let mapOn = []
        this.map.on('measurefinish', (evt) => {
            mapOn = writeResults(evt)
            L.polygon([mapOn]).addTo(this.map).showMeasurements({imperial: true})
        })
        this.map.on('zoomed', () => {
            let measurementElementZoom = document.querySelectorAll('div[title="Segment length"]')
            if (!!measurementElementZoom) {
                document.querySelectorAll('div[title="Segment length"]').forEach((e) => e.remove())
            }
        })

        // Initialize Base Map
        this.baseMaps = initBaseMaps(this.map, nearmapApiKey)
        this.setAutomaticBaseMapSwitching()
        // Initialize Controls
        this.searchControl = initSearchControl(this.map, hereApiKey, DefaultIcon)
        initReverseGeocodeListener(this.map, hereApiKey)
        // Initialize OverlayLayers
        this.overlayLayers = initOverlayLayers()
    }

    changeLayer({latitude, longitude}) {
        // You can use flyTo if you want to zoom and animate as well
        // this.map = this.map.panTo(new L.LatLng(latitude, longitude), 22)
        this.map = this.map.setView([latitude, longitude], 13)
    }

    getZoomMap(setZoom) {
        this.map.on(
            'zoomend',
            () => {
                setZoom(this.map.getZoom())
            },
            this
        )
    }

    setActiveOverlayLayers(nextActiveLayers) {
        const layerKeysToRemove = difference(this.activeOverlayLayers, nextActiveLayers)
        const layerKeysToAdd = difference(nextActiveLayers, this.activeOverlayLayers)
        this._removeLayers(layerKeysToRemove)
        this._addLayers(layerKeysToAdd)
        this.activeOverlayLayers = nextActiveLayers
    }

    updateDealsLayer(deals, onMarkerClick) {
        //checks for & reuses existing layer instance or instantiates new.
        const dealsMarkers = generateMarkers(deals, onMarkerClick, this.overlayLayers.dealsMarkers)
        this.overlayLayers = {
            ...this.overlayLayers,
            dealsMarkers
        }
    }

    filterLayer({layerKey, query}) {
        const layer = this.overlayLayers[layerKey]
        if (layer) {
            layer.setWhere(query)
        }
    }

    setBaseMap(baseMapKey) {
        const newBaseMap = this.baseMaps[baseMapKey]

        Object.keys(this.baseMaps)
            .filter((key) => key !== baseMapKey)
            .forEach((baseMapKey) => {
                const layer = this.baseMaps[baseMapKey]
                if (layer) {
                    this.map.removeLayer(layer)
                }
            })

        newBaseMap.addTo(this.map)
        this.map.off('zoomend', this._enableAutomaticMapSwitch, this)
    }

    setAutomaticBaseMapSwitching() {
        const newBaseMap =
            this.map.getZoom() > AUTO_TILE_SWITCH_TRIGGER_ZOOM
                ? this.baseMaps[BASEMAPS_KEYS.nearmap]
                : this.baseMaps[BASEMAPS_KEYS.gmaps]

        Object.keys(this.baseMaps).forEach((baseMapKey) => {
            const layer = this.baseMaps[baseMapKey]
            if (layer) {
                this.map.removeLayer(layer)
            }
        })

        newBaseMap.addTo(this.map)
        this.map.on('zoomend', this._enableAutomaticMapSwitch, this)
    }

    _enableAutomaticMapSwitch() {
        const gmapsTileBaseLayer = this.baseMaps[BASEMAPS_KEYS.gmaps]
        const nearmapTileBaseLayer = this.baseMaps[BASEMAPS_KEYS.nearmap]
        if (this.map.getZoom() > AUTO_TILE_SWITCH_TRIGGER_ZOOM) {
            this.map.removeLayer(gmapsTileBaseLayer)
            nearmapTileBaseLayer.addTo(this.map)
        } else {
            this.map.removeLayer(nearmapTileBaseLayer)
            gmapsTileBaseLayer.addTo(this.map)
        }
    }

    _addLayers(layersKeys) {
        if (layersKeys && layersKeys.length) {
            layersKeys.forEach((layerKey) => {
                const layer = this.overlayLayers[layerKey]
                if (layer) {
                    this.map.addLayer(layer)
                }
            })
        }
    }

    _removeLayers(layersKeys) {
        if (layersKeys && layersKeys.length) {
            layersKeys.forEach((layerKey) => {
                const layer = this.overlayLayers[layerKey]
                if (layer) {
                    this.map.removeLayer(layer)
                }
            })
        }
    }
}

export default SolarSightMap
