import {useHistory, useLocation} from 'react-router-dom'
import {Button} from 'antd'
import {signOut} from '../../auth/auth'
import styles from './SignOut.module.css'

function SignOut() {
    const location = useLocation()
    const history = useHistory()

    const handleClick = (event) => {
        event.preventDefault()
        const queryParamString = location.search
        signOut(() => history.push(`/login${queryParamString}`))
    }
    return (
        <Button type="primary" className={styles.signOut} onClick={handleClick}>
            Sign Out
        </Button>
    )
}

export default SignOut
