import L from 'leaflet'
import * as esri from 'esri-leaflet'
// import * as esriCluster from 'esri-leaflet-cluster'
import {
    getColorAllowablePvKv,
    getColorByMw,
    jcplhcPopupTemplate,
    orhcPopupTemplate,
    popupTemplate,
    pseghcPopupTemplate,
    getUtilityTerritoryBoundaryColor,
    utbnjPopupTemplate
} from './utils'

export function initOverlayLayers() {
    const precision = 6

    const foregroundCanvasRenderer = L.canvas({
        pane: 'foregroundFeature',
        tolerance: 3
    })

    /* Pepco Layers */

    const pepco = esri.featureLayer({
        pane: 'foregroundFeature',
        url:
            'https://services3.arcgis.com/agWTKEK7X5K1Bx7o/ArcGIS/rest/services/PHI_Hosting_Capacity_Public/FeatureServer/1',
        precision,
        renderer: foregroundCanvasRenderer,
        style: function (feature) {
            const AllowablePvKv =
                feature && feature.properties && feature.properties.Allowable_PV_kW
            const color = getColorAllowablePvKv(AllowablePvKv)
            return {color, weight: 2}
        }
    })

    pepco.bindPopup(function (layer) {
        return L.Util.template(popupTemplate('Pepco'), layer.feature.properties)
    })

    const backgroundCanvas9weightRenderer = L.canvas({
        pane: 'backgroundFeature9weight'
    })

    const backgroundCanvas4weightRenderer = L.canvas({
        pane: 'backgroundFeature4weight'
    })

    const crossJurisdictionPri = esri.featureLayer({
        pane: 'backgroundFeature9weight',
        url:
            'https://services3.arcgis.com/agWTKEK7X5K1Bx7o/ArcGIS/rest/services/CrossBorderFeeders/FeatureServer/0',
        precision,
        renderer: backgroundCanvas9weightRenderer,
        style: function () {
            const color = '#0070ff'
            return {color, weight: 9, opacity: 0.6}
        }
    })

    const crossJurisdictionSec = esri.featureLayer({
        pane: 'backgroundFeature9weight',
        url:
            'https://services3.arcgis.com/agWTKEK7X5K1Bx7o/ArcGIS/rest/services/CrossBorderFeeders/FeatureServer/1',
        precision,
        renderer: backgroundCanvas9weightRenderer,
        style: function () {
            const color = '#0070ff'
            return {color, weight: 9, opacity: 0.6}
        }
    })

    const lvac = esri.featureLayer({
        pane: 'backgroundFeature4weight',
        url:
            'https://services3.arcgis.com/agWTKEK7X5K1Bx7o/ArcGIS/rest/services/LVACCapNetSec/FeatureServer/1',
        precision,
        renderer: backgroundCanvas4weightRenderer,
        style: function () {
            const color = 'purple'
            return {color, weight: 3, opacity: 0.7}
        }
    })

    /* New Jersey layers  */
    /* Atlantic City Electronic Hosting */
    const ace = esri.featureLayer({
        pane: 'foregroundFeature',
        url:
            'https://services3.arcgis.com/agWTKEK7X5K1Bx7o/ArcGIS/rest/services/PHI_Hosting_Capacity_Public/FeatureServer/2',
        precision,
        renderer: foregroundCanvasRenderer,
        style: function (feature) {
            const AllowablePvKv =
                feature && feature.properties && feature.properties.Allowable_PV_kW
            const color = getColorAllowablePvKv(AllowablePvKv)
            return {color, weight: 2}
        }
    })

    ace.bindPopup(function (layer) {
        return L.Util.template(popupTemplate('Ace'), layer.feature.properties)
    })

    /* Public Service Electric & Gas Hosting Capacity */
    const pseghc = esri.featureLayer({
        url:
            'https://services.arcgis.com/yHb9HdkiNl1PZaOr/arcgis/rest/services/SolarPowerSuitability_2/FeatureServer/0',
        pointToLayer: function (feature, latlng) {
            const mw = feature && feature.properties && feature.properties['AVAILABILITYSYMBOL']
            const color = getColorByMw(mw)

            let circleMarker = L.circleMarker(latlng, {
                renderer: foregroundCanvasRenderer,
                color: '#000000',
                weight: 1,
                radius: 5,
                fillOpacity: 1,
                fillColor: `${color}`
            })

            return circleMarker
        }
    })

    pseghc.bindPopup(function (layer) {
        return L.Util.template(popupTemplate(null, pseghcPopupTemplate), layer.feature.properties)
    })

    /* Jersey Central Power & Light Hosting Capacity */
    const jcplhc = esri.featureLayer({
        url:
            'https://services9.arcgis.com/QhIvHlqYDjdoPWZG/arcgis/rest/services/Solar_Accommodation_Limit_2020/FeatureServer/0',
        pointToLayer: function (feature, latlng) {
            const mw = feature && feature.properties && feature.properties.RemainNum
            const color = getColorByMw(mw)

            let circleMarker = L.circleMarker(latlng, {
                renderer: foregroundCanvasRenderer,
                color: '#000000',
                weight: 1,
                radius: 5,
                fillOpacity: 1,
                fillColor: `${color}`
            })

            return circleMarker
        }
    })

    jcplhc.bindPopup(function (layer) {
        return L.Util.template(popupTemplate(null, jcplhcPopupTemplate), layer.feature.properties)
    })

    /* Orange & Rockland Hosting Capacity */
    const orhc = esri.featureLayer({
        pane: 'foregroundFeature',
        url:
            'https://services.arcgis.com/ciPnsNFi1JLWVjva/arcgis/rest/services/ORUNJ_HostingCapacity/FeatureServer/0',
        precision,
        renderer: foregroundCanvasRenderer,
        style: function (feature) {
            const mw = feature && feature.properties && feature.properties['MAX_FDR_HC'] * 1000
            const color = getColorByMw(mw)
            return {color, weight: 2}
        }
    })

    orhc.bindPopup(function (layer) {
        return L.Util.template(popupTemplate(null, orhcPopupTemplate), layer.feature.properties)
    })

    /* NJ Utility territory boundaries */
    const utbnj = esri.featureLayer({
        pane: 'foregroundFeature',
        url: 'https://mapsdep.nj.gov/arcgis/rest/services/Features/Utilities/MapServer/10',
        precision,
        renderer: foregroundCanvasRenderer,
        style: function (feature) {
            const zone = feature && feature.properties && feature.properties['LABEL']
            const color = getUtilityTerritoryBoundaryColor(zone)
            return {color, weight: 2}
        }
    })

    utbnj.bindPopup(function (layer) {
        return L.Util.template(popupTemplate(null, utbnjPopupTemplate), layer.feature.properties)
    })

    return {
        pepco,
        crossJurisdictionPri,
        crossJurisdictionSec,
        lvac,
        ace,
        jcplhc,
        orhc,
        pseghc,
        utbnj
    }
}
